:root {
  /* Colors */
  --header-bg-color: rgba(76, 175, 80, 1);
  --button-bg-color: rgba(255, 87, 34, 1);
  --page-bg-color: rgba(245, 245, 245, 1);
  --main-text-color: rgba(51, 51, 51, 1);
  --secondary-text-color: rgba(102, 102, 102, 1);
  --accent-color: rgba(63, 81, 181, 1);
  --card-bg-color: rgba(255, 255, 255, 1);
  --shadow-light-color: rgba(0, 0, 0, 0.1);
  --shadow-dark-color: rgba(0, 0, 0, 0.2);
  --button-hover-bg-color: rgba(230, 74, 25, 1);
  --modal-bg-color: rgba(0, 0, 0, 0.4);
  --modal-content-bg-color: rgba(255, 255, 255, 1);
  --modal-shadow-color: rgba(0, 0, 0, 0.3);
  --input-border-color: rgba(204, 204, 204, 1);

  /* Spacing */
  --spacing-small: 10px;
  --spacing-medium: 20px;
  --spacing-large: 30px;
  --spacing-xlarge: 40px;

  /* Font Sizes */
  --font-size-small: 0.9em;
  --font-size-medium: 1em;
  --font-size-large: 1.8em;
  --font-size-xlarge: 2em;

  /* Border Radius */
  --border-radius-small: 5px;
  --border-radius-medium: 15px;
  --border-radius-large: 25px;
}