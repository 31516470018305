.challenge-item {
    background-color: var(--card-bg-color);
    padding: var(--spacing-medium);
    border-radius: var(--border-radius-medium);
    box-shadow: 0 4px 8px var(--shadow-light-color);
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
    overflow: hidden; /* Prevent content from overflowing */
    width: 100%;
  }
  
  .challenge-item:hover {
    box-shadow: 0 8px 16px var(--shadow-dark-color);
    transform: translateY(-5px);
  }
  
  .challenge-item h3 {
    font-weight: bold;
    margin-bottom: var(--spacing-small);
    color: var(--main-text-color);
  }
  
  .challenge-item .short-description {
    color: var(--secondary-text-color);
    font-size: var(--font-size-small);
    font-weight: normal;
    margin-bottom: var(--spacing-small);
  }
  
  .challenge-item .expand-button {
    margin-top: var(--spacing-small);
    padding: var(--spacing-small) var(--spacing-medium);
    background-color: transparent;
    color: var(--accent-text-color);
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius-small);
    font-size: var(--font-size-small);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s, border 0.3s;
  }
  
  .challenge-item .expand-button:hover {
    background-color: var(--button-hover-bg-color);
    color: var(--main-text-color);
    border: none; /* Remove the border on hover */
    transform: scale(1.02);
  }
  
  .challenge-item .details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out, padding 0.5s ease-out;
    opacity: 0; /* Initially hidden */
    padding: 0; /* Initially no padding */
  }
  
  .challenge-item .details.expanded {
    max-height: 500px; /* Arbitrary value that should be large enough to show all content */
    opacity: 1; /* Fade in the details smoothly */
    padding: var(--spacing-small) 0; /* Add padding once expanded */
  }
  
  .challenge-item .full-description {
    color: var(--secondary-text-color);
    font-size: var(--font-size-small);
    line-height: 1.4;
    margin-bottom: var(--spacing-small);
  }
  
  .challenge-item .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacing-small);
    box-shadow: none;
  }
  
  .challenge-item .username {
    font-size: var(--font-size-small);
    color: var(--link-color);
    margin: var(--spacing-small);
  }
  
  .challenge-item .link {
    font-size: var(--font-size-small);
    color: var(--link-color);
    margin: var(--spacing-small);
  }
  
  .challenge-item .link a {
    color: var(--link-color);
    text-decoration: underline;
  }
  
  .challenge-item .link a:hover {
    color: var(--link-hover-color);
    text-decoration: none;
  }
  