#challenge-list {
  margin-top: var(--spacing-xlarge);
  text-align: center;
}

#challenge-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1000px, 1fr));
  gap: var(--spacing-medium);
  list-style-type: none;
  padding: 0;
}
