form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
}

form input, form textarea {
  padding: var(--spacing-medium);
  font-size: var(--font-size-medium);
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius-small);
}

form textarea {
  resize: vertical;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-small);
}

form button {
  padding: var(--spacing-medium) var(--spacing-large);
  background-color: var(--button-bg-color);
  color: var(--card-bg-color);
  border: none;
  border-radius: var(--border-radius-large);
  font-size: var(--font-size-medium);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: auto;
}

form button:hover {
  background-color: var(--button-hover-bg-color);
  transform: scale(1.05);
}
