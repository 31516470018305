.new-challenge-button.near-footer,
.new-challenge-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: var(--spacing-small) var(--spacing-medium);
  background-color: var(--button-bg-color);
  color: var(--card-bg-color);
  border: none;
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-medium);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s, bottom 0.3s ease;
  box-shadow: 0 4px 8px var(--shadow-light-color);
  z-index: 1100;
}

.new-challenge-button.near-footer {
  bottom: 10px;
}

.new-challenge-button:hover {
  background-color: var(--button-hover-bg-color);
  transform: scale(1.05);
  box-shadow: 0 8px 16px var(--shadow-dark-color);
}

main {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-small);
}