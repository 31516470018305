.header {
  width: 100%;
  background-color: var(--header-bg-color);
  padding: var(--spacing-small);
  text-align: center;
  box-shadow: 0 4px 8px var(--shadow-light-color);
  z-index: 1000;
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}