.modal {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg-color);
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease-in-out;
}
  
  .modal.open {
    opacity: 1;
  }
  
  .modal-title {
    font-size: var(--font-size-large);
    font-weight: bold;
    margin-bottom: var(--spacing-medium);
    text-align: center;
    color: var(--main-text-color);
  }
  
  .modal-content {
    position: relative;
    background-color: var(--modal-content-bg-color);
    padding: var(--spacing-xlarge);
    border-radius: var(--border-radius-medium);
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: 0 5px 15px var(--modal-shadow-color);
    animation: slideDown 0.4s;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .close-btn {
    position: absolute;
    top: var(--spacing-small);
    right: var(--spacing-medium);
    font-size: var(--font-size-large);
    cursor: pointer;
  }