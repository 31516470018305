.header-actions,
.header-actions.fixed  {
    top: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-small) var(--spacing-small);
    box-shadow: 0 4px 8px var(--shadow-light-color);
    background-color: var(--card-bg-color);
    z-index: 1000;
    transition: all 0.3s ease;
  }
  
  .header-actions.fixed {
    position: fixed;
  }

  .header-action-button {
    margin: 0 var(--spacing-medium);
    padding: var(--spacing-small) var(--spacing-large);
    background-color: var(--button-bg-color);
    color: var(--card-bg-color);
    border: none;
    border-radius: var(--border-radius-medium);
    font-size: var(--font-size-medium);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  