button {
    padding: var(--spacing-small) var(--spacing-medium);
    background-color: var(--button-bg-color);
    color: var(--card-bg-color);
    border: none;
    border-radius: var(--border-radius-large);
    cursor: pointer;
    font-size: var(--font-size-medium);
    transition: background-color 0.3s, transform 0.2s;
    width: auto;
    margin-bottom: var(--spacing-medium);
  }
  
  button:hover {
    background-color: var(--button-hover-bg-color);
    transform: scale(1.05);
  }