.footer {
    background-color: var(--card-bg-color);
    padding: var(--spacing-medium);
    text-align: center;
    box-shadow: 0 -4px 8px var(--shadow-light-color);
    margin-top: var(--spacing-large);
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    color: var(--secondary-text-color);
    font-size: var(--font-size-small);
  }
  
  .footer-links {
    margin-top: var(--spacing-small);
  }
  
  .footer-links a {
    color: var(--accent-text-color);
    text-decoration: none;
    margin: 0 var(--spacing-small);
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: var(--link-hover-color);
  }
  